<div class="wrapper2">
  <div class="header">
    <div class="head">
      <div class="image-wrapper">
        <img
          *ngIf="parameter.imageId"
          src="{{imageURL}}{{
            parameter.imageId
          }}"
          height="65 px"
        />
      </div>

      <div class="title-wrapper">
        <div class="sub-title">
          <span></span>
        </div>
        <div class="title">
          <h2>{{ parameter.salesname ? parameter.salesname : label.title }}</h2>
        </div>
      </div>
      <div class="logo-wrapper">
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_125_866)">
            <path
              d="M47.7777 30C47.7777 39.8248 39.8248 47.7778 29.9999 47.7778V30H47.7777ZM29.9999 12.2222V30H12.2222C12.2222 20.1752 20.1751 12.2222 29.9999 12.2222Z"
              fill="#0166B1"
            />
            <path
              d="M30 12.2223C39.8248 12.2223 47.7777 20.1752 47.7777 30H30V12.2223ZM12.2222 30H30V47.7778C20.1751 47.7778 12.2222 39.8249 12.2222 30Z"
              fill="white"
            />
            <path
              d="M30 12.2222V13.1969C39.2643 13.1969 46.803 20.7339 46.803 30H47.7778C47.7778 20.1822 39.8178 12.2222 30 12.2222ZM30 46.803C20.7357 46.803 13.197 39.2661 13.197 30H12.2222C12.2222 39.8178 20.1822 47.7778 30 47.7778M30 0C13.4319 0 0 13.4319 0 30C0 46.5681 13.4319 60 30 60C46.5681 60 60 46.5681 60 30C60 13.4319 46.5681 0 30 0ZM58.0492 30C58.0492 45.4904 45.4921 58.0492 30 58.0492C14.5079 58.0492 1.95085 45.4904 1.95085 30C1.95085 14.5096 14.5096 1.95085 30 1.95085C45.4904 1.95085 58.0492 14.5096 58.0492 30Z"
              fill="#6F6F6F"
            />
            <path
              d="M17.1796 15.5274C18.1383 14.5246 18.6811 13.366 17.7224 12.26C17.2008 11.6597 16.325 11.5284 15.5989 11.8242L15.5267 11.8522L15.5496 11.7892C15.6571 11.5039 15.7258 10.5939 14.9451 9.96557C14.5645 9.6593 14.0869 9.54555 13.604 9.59455C12.7035 9.68555 12.011 10.2963 10.1254 12.3842C9.55617 13.0142 8.72791 14.0083 8.23096 14.6523L13.4102 19.5316C15.1266 17.645 15.8262 16.9432 17.1796 15.5274ZM10.4214 14.3338C11.4647 13.0667 12.5731 11.9169 13.0754 11.4619C13.234 11.3184 13.4067 11.1749 13.6164 11.1189C13.9565 11.0261 14.3072 11.2659 14.3865 11.6124C14.4658 11.9607 14.2455 12.2915 14.0006 12.5645C13.449 13.1823 11.4259 15.2841 11.4259 15.2841L10.4214 14.3338ZM12.4498 16.2449C12.4498 16.2449 14.42 14.1763 15.0632 13.5148C15.3187 13.2523 15.4826 13.093 15.6588 13.0072C15.8862 12.897 16.1346 12.8742 16.3567 13.0212C16.5752 13.1665 16.6756 13.4168 16.621 13.6653C16.5558 13.9611 16.3126 14.2428 16.1117 14.4581C15.8403 14.7468 13.5036 17.2302 13.5001 17.2337L12.4498 16.2449ZM30.5921 9.7083L32.2504 6.01909L32.3561 5.69182L32.3262 6.03309L32.5006 10.9631C33.0822 11.0174 33.6831 11.0979 34.2752 11.1959L34.0056 3.89447C33.1773 3.80346 32.3526 3.73871 31.5279 3.7037L29.8943 7.73069L29.8361 8.02996L29.7779 7.73069L28.1461 3.7037C27.3214 3.73871 26.4966 3.80346 25.6684 3.89447L25.3988 11.1959C25.9909 11.0979 26.5918 11.0191 27.1733 10.9631L27.3478 6.03309L27.3179 5.69182L27.4236 6.01909L29.0819 9.7083H30.5921ZM44.3641 17.3405C44.7711 17.7745 45.358 18.4746 45.6875 18.8998L51.7567 15.0741C51.4536 14.6803 50.9707 14.0818 50.6095 13.66L46.766 16.1837L46.5034 16.4077L46.699 16.1242L48.3961 12.7605L47.196 11.5687L43.8072 13.254L43.5217 13.4483L43.749 13.1875L46.292 9.37228C45.8443 8.99426 45.4144 8.64774 44.8663 8.22772L41.014 14.2498C41.5022 14.6226 42.1454 15.1704 42.556 15.5431L46.1827 13.674L46.4153 13.5043L46.2444 13.7353L44.3641 17.3405Z"
              fill="#6F6F6F"
            />
          </g>
          <defs>
            <clipPath id="clip0_125_866">
              <rect width="60" height="60" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>

    <div class="nav">
      <div class="nav-item">
        <button
          [ngClass]="text === 'xs' ? 'active' : ''"
          (click)="!error && changeField('xs')"
        >
          XS-TEXT  <mat-icon *ngIf="textActions['xs'].status === 'saved'" class="icon-wrapper" svgIcon="check"></mat-icon><mat-icon *ngIf="textActions['xs'].status === 'draft'" class="icon-wrapper-draft" svgIcon="edit"></mat-icon>
        </button>
      </div>
      <div class="nav-item">
        <button
          [ngClass]="text === 's' ? 'active' : ''"
          (click)="!error && changeField('s')"
        >
          KURZTEXT  <mat-icon *ngIf="textActions['s'].status === 'saved'" class="icon-wrapper" svgIcon="check"></mat-icon><mat-icon *ngIf="textActions['s'].status === 'draft'" class="icon-wrapper-draft" svgIcon="edit"></mat-icon>
        </button>
      </div>
      <div class="nav-item">
        <button
          [ngClass]="text === 'l' ? 'active' : ''"
          (click)="!error && changeField('l')"
        >
          LANGTEXT  <mat-icon *ngIf="textActions['l'].status === 'saved'" class="icon-wrapper" svgIcon="check"></mat-icon><mat-icon *ngIf="textActions['l'].status === 'draft'" class="icon-wrapper-draft" svgIcon="edit"></mat-icon>
        </button>
      </div>
      <div class="nav-item">
        <button
          [ngClass]="text === 'c' ? 'active' : ''"
          (click)="!error && changeField('c')"
        >
          KUNDENVORTEILE  <mat-icon *ngIf="textActions['c'].status === 'saved'" class="icon-wrapper" svgIcon="check"></mat-icon><mat-icon *ngIf="textActions['c'].status === 'draft'" class="icon-wrapper-draft" svgIcon="edit"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="grid-wrapper">
    <div class="grid-left-sidebar" [ngClass]="leftopen ? 'open' : 'close'">
      <div class="sidebar-wrapper">
        <button
          (click)="lefta()"
          class="btn-close"
          mat-mini-fab
          color="primary"
          aria-label="Open"
        >
          <mat-icon *ngIf="!leftopen" svgIcon="open"></mat-icon>
          <mat-icon *ngIf="leftopen" svgIcon="close"></mat-icon>
        </button>

        <div class="wrapper-text">
          <div *ngIf="leftopen == false" class="text"><h3>Historie</h3></div>
          <div *ngIf="leftopen == true">
            <div class="left-sidebar-wrapper">
              <div class="left-sidebar-header">
                <h3>Historie</h3>
              </div>
              <div class="h-select-felx">
                <div
                  class="h-select-wrapper"
                  *ngFor="
                    let i of textActions[text].history.slice().reverse();
                    index as ind
                  "
                >
                  <div class="h-select-out">
                    <div
                      [ngClass]=" textActions[text].historyIndex === textActions[text].history.length - ind - 1 ?  'h-select h-select-active' : 'h-select' "

                      (click)="
                        changeTextIndex(
                          textActions[text].history.length - ind - 1,
                          text
                        )
                      "
                    >
                      {{ i.date | date : "medium" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-main">
      <div class="grid-header">
        <div class="chat-wrapper">
          <div class="frame">
            <!-- <div
              class="wrapper-fieldset-middle item"
              *ngIf="textActions[text].systemMessage as groupName"
            >
              <div class="info-wrapper">
                <mat-icon svgIcon="info2"></mat-icon>
                <span>{{ groupName }}</span>
              </div>
            </div> -->

            <div class="text-section">
              <div
                class="center-loader"
                *ngIf="!textActions[text].message.text &&  parameter.salesname"
              >
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <textarea
                *ngIf="textActions[text].message.text"
                [disabled]="edit"
                [ngClass]="edit ? '' : 'textarea-background'"
                [(ngModel)]="textActions[text].message.text"
              ></textarea>
              <div
                *ngIf="textActions[text].message.text"
                class="action-wrapper"
              >
                <button
                  mat-mini-fab
                  color="primary"
                  aria-label="copy"
                  (click)="copy()"
                >
                  <mat-icon svgIcon="copy"></mat-icon>
                </button>

                <button
                  mat-mini-fab
                  [color]="edit ? 'primary' : 'accent'"
                  aria-label="edit"
                  (click)="editText()"
                >
                  <mat-icon svgIcon="edit"></mat-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="frame-text">
            <button
              mat-fab
              [disabled]="!edit || !parameter.salesname"
              (click)="openDialog()"
              color="primary"
              aria-label="Lamp"
            >
              <mat-icon svgIcon="lamp"></mat-icon>
            </button>
            <div class="text-wrapper">
              <textarea
                [(ngModel)]="textActions[text].message.prompt"
                (keydown.enter)="sendViaEnterKey()"
                [disabled]="!edit || !parameter.salesname"
                class="example-full-width"
                matInput
                placeholder="Anweisungen an die KI um den Text zu verbessern"
              ></textarea>
              <button
                mat-mini-fab
                color="primary"
                aria-label="Send"
                (click)="send()"
                [disabled]="!edit || !parameter.salesname"
              >
                <mat-icon svgIcon="update"></mat-icon>
              </button>
            </div>
            <button mat-fab color="accent"   [disabled]="!edit || !parameter.salesname" aria-label="Send" (click)="save()">
              <mat-icon svgIcon="send"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-right-sidebar" [ngClass]="rightopen ? 'open' : 'close'">
      <div class="sidebar-wrapper">
        <button
          (click)="righta()"
          class="btn-close"
          mat-mini-fab
          color="primary"
          aria-label="Open"
        >
          <mat-icon *ngIf="!rightopen" svgIcon="open"></mat-icon>
          <mat-icon *ngIf="rightopen" svgIcon="close"></mat-icon>
        </button>

        <div class="wrapper-text">
          <div *ngIf="rightopen === false" class="text">
            <h3>Produktbeschreibung</h3>
          </div>
          <div *ngIf="rightopen === true" class="text">
            <div class="right-sidebar-wrapper">
              <div class="right-sidebar-header">
                <h3>Produktbeschreibung</h3>
              </div>
              <div class="h-select-felx2">
                <div class="frame">
                  <div
                    class="desc-section"
                    [innerHTML]="productDesc | replaceLineBreaks"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
