import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  help: Record<string, [{prompt: string, titel: string, ziel: string}]> = {}

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {help:  any}

  ) {}

  objectKeys = Object.keys
  active = ''

  ngOnInit(): void {

    this.help = this.data.help;
    this.active = Object.keys(this.help)[0];
    console.log("Help:", this.data.help);
  }

  clickOnCard(item: {prompt: string, titel: string, ziel: string}){
    this.dialogRef.close(item)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeTab(value: string) {
    this.active = value;
  }

}
