import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { ApplicationRef, DoBootstrap, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TextAiModule } from './text-ai/text-ai.module';
import { createCustomElement } from '@angular/elements';
import { TextAiComponent } from './text-ai/text-ai.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [BrowserModule, TextAiModule, BrowserAnimationsModule, FormsModule, MatDialogModule],
  providers: [],
})
export class ElementModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap(appRef: ApplicationRef) {
    if (!customElements.get('text-ai')) {
      const textAIComponent = createCustomElement(TextAiComponent, {
        injector: this.injector,
      });

      customElements.define('text-ai', textAIComponent);
    }
  }
}
