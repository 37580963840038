import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextAiComponent } from './text-ai.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatBadgeModule} from '@angular/material/badge';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatSliderModule} from '@angular/material/slider';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { FormsModule } from '@angular/forms';
import { ReplaceLineBreaks } from 'src/pipes/replace-line-breaks.pipe';
import { DialogComponent } from './components/dialog/dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [
    TextAiComponent,
    ReplaceLineBreaks,
    DialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSidenavModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    MatDividerModule,
    MatBadgeModule,
    MatExpansionModule,
    MatInputModule,
    MatSliderModule,
    ClipboardModule,
    MatSnackBarModule
  ],
  exports: [
    TextAiComponent
  ]
})
export class TextAiModule { }
