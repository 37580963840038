
export type FormatMapping = {
  [key in string]: {
    fieldSubentityId: string;
    fieldPath: string;
  }
}

export const formatMapping: FormatMapping = {
  'xs': {
    fieldSubentityId: 'BuiltIntoCBTLangMarketValidity',
    fieldPath: 'BuiltIntoCBTLangMarketValidity.XSText',
  },
  's': {
    fieldSubentityId: 'BuiltIntoLangMarketValidity02',
    fieldPath: 'BuiltIntoLangMarketValidity02.ShortText',
  },
  'l': {
    fieldSubentityId: 'BuiltIntoCBTLangMarketValidity',
    fieldPath: 'BuiltIntoCBTLangMarketValidity.LongText',
  },
  'c': {
    fieldSubentityId: 'BuiltIntoCBTLangMarketValidity',
    fieldPath: 'BuiltIntoCBTLangMarketValidity.CustomerBenefits',
  },
}

export function getMessageType(fieldPath: string): 'xs' | 's' | 'l' | 'c' | null {
   switch (fieldPath) {
     case 'BuiltIntoCBTLangMarketValidity.XSText': {
       return 'xs';

     }
     case 'BuiltIntoCBTLangMarketValidity.LongText' : {
       return 'l';
     }
     case 'BuiltIntoLangMarketValidity02.ShortText' : {
       return 's';
     }
     case 'BuiltIntoCBTLangMarketValidity.CustomerBenefits' : {
       return 'c';
     }

   }
   return null;

}

export interface TextMessage {
  text: string;
  prompt: string;
  date: Date;

}
export interface Message {
  history: TextMessage[];
  historyIndex: number,
  message: TextMessage,
  systemMessage: string
  status: 'draft' | 'saved' | null
}



