export const tooltip = {

    fav: "Letzten Textvorschlag auswählen",
    save: "Speichern",
    xsText: "XS-Text",
    sText: "Kurztext",
    lText: "Langtext",
    cText: "Kundenvorteile",
    model: "Zeigt das verwendete KI-Sprachmodell an.",
    temerature: "Je höher das Kreativitätslevel eingestellt ist, umso zufälliger bzw. kreativer werden die Texte generiert. Standardmäßig ist es sehr strikt eingestellt. Je weiter der Regler nach rechts bewegt wird, umso kreativer werden die Texte generiert.",
    maxResponseLength: "Gibt die maximale Zeichenlänge des generierten Textes an.",
    template: "Zeigt das verwendete Template an."

}
