<div class="dialog-wrapper">
  <div class="header-action">
    <button
    (click)="onNoClick()"
    mat-mini-fab
    color="primary"
    aria-label="close"
    ><mat-icon svgIcon="close"></mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div class="nav" >
      <div class="nav-item" *ngFor="let key of objectKeys(help)">
        <button [ngClass]="active === key ? 'active' : ''" (click)="changeTab(key)">{{ key | uppercase }}</button>
      </div>

    </div>
    <div  *ngFor="let key of objectKeys(help)" class="content-wrapper">
      <div class="grid-container" *ngIf="active === key">
        <div class="grid-item" *ngFor="let item of help[key]">


            <mat-card class="help-card" (click)="clickOnCard(item)">
              <mat-card-header>
                <mat-card-title>{{ item.titel }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>
                  {{ item.ziel }}
                </p>
                <p>
                  {{ item.prompt }}
                </p>
              </mat-card-content>
            </mat-card>

        </div>

      </div>
    </div>

  </div>

</div>
