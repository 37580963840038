export const label = {
  xsText: "XS-Text",
  sText: "Kurztext",
  lText: "Langtext",
  cText: "Kundenvorteile",
  customerText: "Kundenvorteile",
  title: "Wähle einen Objektkontext, der eine Vertriebsbezeichnung enthält",
  fav: "Letzten Textvorschlag auswählen",
  save: "Speichern",
  chat: "Chat",
  settings: "Konfiguration",
  user: "Benutzer",
  ai: "AI",
  system: "System Nachricht",
  placeholder: "Schreib etwas ..."

}
