import {TextMessage} from './../../config/formatMapping';
import {environment} from './../../environments/environment';
import {MatSnackBar, MatSnackBarRef, MatSnackBarModule} from '@angular/material/snack-bar';

import {
  MatDialog,
} from '@angular/material/dialog';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {
  checkIcon,
  closeIcon,
  cloudIcon,
  copyIcon,
  dislikeIcon,
  draftIcon,
  editIcon,
  info2Icon,
  infoIcon,
  lampIcon,
  likeIcon,
  menuIcon,
  messageIcon,
  openIcon,
  saveIcon,
  sendIcon,
  serverIcon,
  starIcon,
  updateIcon,
  userIcon,
} from './components/icons/icon';
import {tooltip} from './../../config/tooltip';
import {label} from './../../config/label';
import {WSService} from './services/ws.service';
import {
  FormatMapping,
  Message,
  formatMapping,
  getMessageType,
} from 'src/config/formatMapping';
import {Clipboard} from '@angular/cdk/clipboard';
import {DialogComponent} from './components/dialog/dialog.component';

@Component({
  selector: 'app-text-ai',
  templateUrl: './text-ai.component.html',
  styleUrls: ['./text-ai.component.scss', './../../styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextAiComponent implements AfterViewInit, OnInit {
  format: FormatMapping = formatMapping;
  text: 'xs' | 's' | 'l' | 'c' = 's';
  transactions: any[] = [];
  textIndex = -1;
  productDesc = '';
  help = {};
  edit = true;

  imageURL = environment.imageURL


  leftopen = false;

  editText() {
    this.edit = !this.edit;
    this.textActions[this.text].status = 'draft';

    if (this.edit) {
      this.addNewTextVersionToHistory()
    }
  }

  addNewTextVersionToHistory() {
    const text: string = this.textActions[this.text].message?.text;

    this.textActions[this.text].history.push({
      text: text,
      prompt: this.textActions[this.text].message.prompt,
      date: new Date(),
    });

    this.textActions[this.text].historyIndex = this.textActions[this.text].history.length - 1;
  }

  lefta() {
    this.leftopen = !this.leftopen;
  }

  rightopen = false;

  righta() {
    this.rightopen = !this.rightopen;
  }

  changeTextIndex(index: number, textType: string) {
    if (this.textActions[textType].history[index].text) {
      this.textActions[textType].message.text =
        this.textActions[textType].history[index].text;
      this.textActions[textType].message.date =
        this.textActions[textType].history[index].date;
      this.textActions[textType].message.prompt =
        this.textActions[textType].history[index].prompt;
      this.textActions[textType].historyIndex = index;
    }
  }

  textActions: Record<string, Message> = {
    xs: {
      history: new Array<TextMessage>(),
      historyIndex: 0,
      message: {} as TextMessage,
      systemMessage: '',
      status: null,

    },
    s: {
      history: new Array<TextMessage>(),
      historyIndex: 0,
      message: {} as TextMessage,
      systemMessage: '',
      status: null
    },
    l: {
      history: new Array<TextMessage>(),
      historyIndex: 0,
      message: {} as TextMessage,
      systemMessage: '',
      status: null
    },
    c: {
      history: new Array<TextMessage>(),
      historyIndex: 0,
      message: {} as TextMessage,
      systemMessage: '',
      status: null
    },
  };

  parameter: {
    entityId: string;
    objectId: string;
    languageId: string;
    marketId: string;
    validityId: string;
    fieldPath: string;
    fieldSubentityId: string;
    userId: string;
    salesname: string | null;
    imageId: string | null;
  } = {
    entityId: this.wsService.entityId,
    objectId: '',
    languageId: '',
    marketId: '',
    validityId: '',
    fieldPath: this.format[this.text].fieldPath,
    fieldSubentityId: this.format[this.text].fieldSubentityId,
    userId: '',
    salesname: '',
    imageId: '',
  };

  accessToken = '';
  wsEndpoint = '';

  lastTransactionFormat = '';

  favIndex = '';
  favIndex2 = '';

  @ViewChild('scrollframe', {static: false}) scrollFrame!: ElementRef;
  @ViewChildren('item') itemElements!: QueryList<any>;

  public items: any = [];
  tooltip = tooltip;
  label = label;
  error = true;

  ngOnInit(): void {
    var url = window.location.search.substring(1);
    var qArray = url.split('&');
    for (var i = 0; i < qArray.length; i++) {
      var pArr = qArray[i].split('=');
      if (pArr[0] === 'entityId') this.parameter.entityId = pArr[1];

      if (pArr[0] === 'objectId') this.parameter.objectId = pArr[1];

      if (pArr[0] === 'marketId') this.parameter.marketId = pArr[1];

      if (pArr[0] === 'languageId') this.parameter.languageId = pArr[1];

      if (pArr[0] === 'validityId') this.parameter.validityId = pArr[1];

      if (pArr[0] === 'userId') this.parameter.userId = pArr[1];

      if (pArr[0] === 'imageId') this.parameter.imageId = pArr[1];

      if (pArr[0] === 'salesname') {
        let name = decodeURI(pArr[1]);
        if (name && name !== 'null') {
          this.parameter.salesname = name;
          this.error = false;
        } else {
          this.parameter.salesname = null;
        }
      }
      if (pArr[0] === 'access_token') {
        this.accessToken = decodeURI(pArr[1]);
      }
      if (pArr[0] === 'wsUrl') {
        this.wsEndpoint = atob(pArr[1]);
      }
    }

    this.wsService.messages$.subscribe((val: any) => {
      if (getMessageType(val?.message?.parameter?.fieldPath as string)) {
        if (val?.message?.context) {
          if (val?.message?.wflcontext?.pim?.description) {
            this.productDesc = val?.message?.wflcontext?.pim?.description;
          }
          if (val?.message?.context?.llm?.text) {
            this.textActions[getMessageType(val?.message?.parameter?.fieldPath as string)!].status = 'draft';
            this.textActions[
              getMessageType(val?.message?.parameter?.fieldPath as string)!
              ].history.push({
              text: val?.message?.context?.llm?.text as string,
              prompt: (val?.message?.context?.user?.prompt as string) || '',
              date: new Date(),
            });
            this.textActions[
              getMessageType(val?.message?.parameter?.fieldPath as string)!
              ].message = {
              text: val?.message?.context?.llm?.text as string,
              prompt: '',
              date: new Date(),
            };
            if (
              this.textActions[
                getMessageType(val?.message?.parameter?.fieldPath as string)!
                ].history.length >= 2
            ) {
              this.textActions[
                getMessageType(val?.message?.parameter?.fieldPath as string)!
                ].systemMessage = '';
            }
            this.textActions[getMessageType(val?.message?.parameter?.fieldPath as string)!].historyIndex = this.textActions[getMessageType(val?.message?.parameter?.fieldPath as string)!].history.length - 1;
          } else {
            if (val?.message?.context?.system?.type === 'system' && val?.message?.context?.system?.system?.type === 'help') {
              this.help = val?.message?.context?.system?.system?.help
            } else if (val?.message?.context?.system?.text) {
              this.textActions[
                getMessageType(val?.message?.parameter?.fieldPath as string)!
                ].systemMessage = val?.message?.context?.system?.text;
              this.snackBar.open(val?.message?.context?.system?.text, undefined, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 5 * 1000,
                panelClass: ['snackbar-info']
              });
            }
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    this.wsService.connect({
      reconnect: false,
      accessToken: this.accessToken,
      wsEndpoint: this.wsEndpoint,
    });
    if (!this.error) {
      this.wsService.sendMessage({
        action: 'subscribe',
        entityId: this.wsService.entityId,
        message: {
          parameter: {
            entityId: this.wsService.entityId,
            objectId: this.parameter.objectId,
            languageId: this.parameter.languageId,
            marketId: this.parameter.marketId,
            validityId: this.parameter.validityId,
            fieldPath: this.parameter.fieldPath,
            fieldSubentityId: this.parameter.fieldSubentityId,
            userId: this.parameter.userId,
          },
          context: {
            id: '-1',
            user: {
              action: 'initial',
              prompt: null,
            },
            system: null,
            llm: null,
            pim: null,
          },
        },
      });
      this.wsService.sendMessage({
        action: 'subscribe',
        entityId: this.wsService.entityId,
        message: {
          parameter: {
            entityId: this.wsService.entityId,
            objectId: this.parameter.objectId,
            languageId: this.parameter.languageId,
            marketId: this.parameter.marketId,
            validityId: this.parameter.validityId,
            fieldPath: this.parameter.fieldPath,
            fieldSubentityId: this.parameter.fieldSubentityId,
            userId: this.parameter.userId,
          },
          context: {
            id: '-1',
            user: {
              action: 'help',
              prompt: null,
            },
            system: null,
            llm: null,
            pim: null,
          },
        },
      });
    }
  }

  copy() {
    this.clipboard.copy(this.textActions[this.text].message.text);
    this.snackBar.open("Der aktuelle Text wurde in die Zwischenablage kopiert", undefined, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5 * 1000,
    })

  }

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private wsService: WSService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    iconRegistry.addSvgIconLiteral(
      'menu',
      sanitizer.bypassSecurityTrustHtml(menuIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'info',
      sanitizer.bypassSecurityTrustHtml(infoIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'message',
      sanitizer.bypassSecurityTrustHtml(messageIcon)
    );

    iconRegistry.addSvgIconLiteral(
      'send',
      sanitizer.bypassSecurityTrustHtml(sendIcon)
    );

    iconRegistry.addSvgIconLiteral(
      'star',
      sanitizer.bypassSecurityTrustHtml(starIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'save',
      sanitizer.bypassSecurityTrustHtml(saveIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'server',
      sanitizer.bypassSecurityTrustHtml(serverIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'cloud',
      sanitizer.bypassSecurityTrustHtml(cloudIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'user',
      sanitizer.bypassSecurityTrustHtml(userIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'copy',
      sanitizer.bypassSecurityTrustHtml(copyIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'like',
      sanitizer.bypassSecurityTrustHtml(likeIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'dislike',
      sanitizer.bypassSecurityTrustHtml(dislikeIcon)
    );

    iconRegistry.addSvgIconLiteral(
      'lamp',
      sanitizer.bypassSecurityTrustHtml(lampIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'open',
      sanitizer.bypassSecurityTrustHtml(openIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'close',
      sanitizer.bypassSecurityTrustHtml(closeIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'update',
      sanitizer.bypassSecurityTrustHtml(updateIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'info2',
      sanitizer.bypassSecurityTrustHtml(info2Icon)
    );
    iconRegistry.addSvgIconLiteral(
      'edit',
      sanitizer.bypassSecurityTrustHtml(editIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'check',
      sanitizer.bypassSecurityTrustHtml(checkIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'draft',
      sanitizer.bypassSecurityTrustHtml(draftIcon)
    );
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {help: this.help},
      height: '80%',
      width: '80%'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.textActions[this.text].message.prompt = result.prompt;
    });
  }

  isExpanded = false;

  getLastTransactionInField() {
    return this.transactions
      .filter((t) => t.parameter.fieldPath === this.parameter.fieldPath)
      ?.slice(-1)[0]?.context;
  }

  getLastLLMTransactionInField() {
    debugger;
    const a = this.transactions.filter((t) => {
      return (
        t.parameter.fieldPath === this.parameter.fieldPath &&
        t.context.llm !== null
      );
    });
    const t = this.transactions
      .filter(
        (t) =>
          t.parameter.fieldPath === this.parameter.fieldPath &&
          t?.context?.llm?.text !== null
      )
      ?.slice(-1)[0]?.context;
    return t;
  }

  changeField(value: 'xs' | 's' | 'l' | 'c') {
    if (value && value !== this.text) {
      this.edit = true;


      this.text = value;
      this.parameter.fieldPath = formatMapping[value].fieldPath;
      this.parameter.fieldSubentityId = formatMapping[value].fieldSubentityId;
      if (!this.textActions[value].message?.text) {
        this.send();

      }
    }
  }

  send() {
    this.textActions[this.text].message.text = '';
    this.textActions[this.text].systemMessage = '';
    const message = {
      action: 'subscribe',
      entityId: this.wsService.entityId,
      message: {
        parameter: {
          entityId: this.wsService.entityId,
          objectId: this.parameter.objectId,
          languageId: this.parameter.languageId,
          marketId: this.parameter.marketId,
          validityId: this.parameter.validityId,
          fieldPath: this.parameter.fieldPath,
          fieldSubentityId: this.parameter.fieldSubentityId,
          userId: this.parameter.userId,
        },
        context: {
          id: null,
          user: {
            action: 'generate',
            prompt: this.textActions[this.text].message.prompt,
          },
          system: {
            text: null,
          },
          llm: {
            text: null,
          },
          pim: {
            text: null,
          },
        },
      },
    };

    this.wsService.sendMessage(message);
    this.textActions[this.text].message.prompt = '';
  }

  save() {
    const message = {
      action: 'subscribe',
      entityId: this.wsService.entityId,
      message: {
        parameter: {
          entityId: this.wsService.entityId,
          objectId: this.parameter.objectId,
          languageId: this.parameter.languageId,
          marketId: this.parameter.marketId,
          validityId: this.parameter.validityId,
          fieldPath: this.parameter.fieldPath,
          fieldSubentityId: this.parameter.fieldSubentityId,
          userId: this.parameter.userId,
        },
        context: {
          id: null,
          user: {
            action: 'save',
            prompt: '',

          },
          system: {
            text: null,
          },
          llm: {
            text: this.textActions[this.text].message?.text ||
              this.textActions[this.text]?.history[
              this.textActions[this.text].history.length - 1
                ]?.text ||
              '',
          },
          pim: {
            text: null,
          },
        },
      },
    };
    this.wsService.sendMessage(message);
    this.textActions[this.text].status = 'saved';
  }

  sendViaEnterKey() {
    if (
      this.edit &&
      this.textActions[this.text].message.prompt.trim().length > 0
    )
      this.send();
  }
}
