import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({name: 'replaceLineBreaks'})
export class ReplaceLineBreaks implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}


  transform(text: string): SafeHtml {
    if(text) {
      return  this.sanitizer.bypassSecurityTrustHtml(text.replace(/\n/g, '<br/>'));
    }
    else {
      return this.sanitizer.bypassSecurityTrustHtml("");
    }
  }
}
