import { Injectable } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import { v4 as uuidv4 } from 'uuid';
import { Observable, timer, Subject, EMPTY, map, mergeAll } from 'rxjs';
import { retryWhen, tap, delayWhen, switchAll, catchError, scan } from 'rxjs/operators';
export const RECONNECT_INTERVAL = 2000;



@Injectable({
  providedIn: 'root'
})
export class WSService {

  public entityId: string = uuidv4();
  private socket$!: any;
  private messagesSubject$ = new Subject<any>();
  public messages$: Observable<any> = this.messagesSubject$.pipe(switchAll<any>(), catchError(e => { throw e }));

  constructor() {
  }


  public connect(cfg: { reconnect: boolean, accessToken: string , wsEndpoint: string} = { reconnect: false, accessToken: '', wsEndpoint: ''}): void {

    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket(cfg.accessToken, cfg.wsEndpoint);
      const messages = this.socket$.pipe(
        tap({
          error: error => console.log(error),
        }), catchError(_ => EMPTY))

      this.messagesSubject$.next(messages);
    }
  }


  private reconnect(observable: Observable<any>): Observable<any> {
    return observable.pipe(retryWhen(errors => errors.pipe(tap(val => console.log('[Data Service] Try to reconnect', val)),
      delayWhen(_ => timer(RECONNECT_INTERVAL)))));
  }

  close() {
    this.socket$.complete();
    this.socket$ = undefined;
  }

  sendMessage(msg: any) {
    this.socket$.next(msg);
  }


  private getNewWebSocket(accessToken: string, wsEndpoint: string) {
    return webSocket({
      url: `${wsEndpoint}?access_token=${accessToken}`,
      protocol: 'websocket' ,
      openObserver: {
        next: () => {
          return {action: "subscribe", entityId: this.entityId }
        }
        ,
        error: (e) => {
          console.log("Err ", e)
        }
      },
      closeObserver: {
        next: () => {
          this.socket$ = undefined;
          this.connect({ reconnect: true, accessToken , wsEndpoint});
        }
      },
    });
  }


}
